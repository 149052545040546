import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import toInteger from 'lodash/parseInt';

import { displayScheduleLabel, stringWithoutTags } from '../../../helpers';
import Money from 'core/api/Money';
import { programBundleCartVar } from 'core/api/apollo/cache';
import { session } from 'core/api/storage';
import { GET_PROGRAM_BUNDLE, LIST_PROGRAM_OPTIONS } from 'core/api/apollo/queries';
import { CREATE_ONE_TIME_PROGRAM } from 'core/api/apollo/mutations';
import { updateUserQuery } from 'core/api/cache/updaters';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

import { Form, FormGroup, Select, Grid, Checkbox, Hint, SanitizedHTML } from 'core/ui/atoms';
import { InputGroupLabel, Radio } from 'core/ui/atoms/V2';
import { Loading } from 'core/ui/components';
import { displayDate } from 'core/ui/dateHelpers';

import { onrampNext } from 'onramp/api/navigation';
import { PROGRAM_FOOD_OPTIONS, PROGRAM_SCHEDULES_KEYS, priceLabel } from 'onramp/constants';

import { ContentButton, ContentForm } from 'onramp/ui/components';

const SESSION_PROGRAM_BUNDLE_CART_KEY = 'SignatureProgramPage/Cart';

const EMPTY_PRICE = { amount: 0, isoCode: 'USD' };
// eslint-disable-next-line react/prop-types
export const ProgramLengthLabel = ({ weeks, discountedWeeklyPrices= []  }) => {
  // eslint-disable-next-line eqeqeq
  const daysStr = weeks == 1 ? 'Week' : 'Weeks';
  const priceBlock = discountedWeeklyPrices ? discountedWeeklyPrices.find((v) => {
    return (v.week === +weeks);
}): {};

  const [original, discounted] = priceBlock ? [priceBlock.originalPrice, priceBlock.discountedPrice] : [EMPTY_PRICE,EMPTY_PRICE];
  const totalPrice = Money(original).toFormat();
  const discountedPrice = Money(discounted).toFormat();

  return (
    <Fragment>
      <span>
        {weeks} {daysStr} - {discountedPrice}
      </span>
      {totalPrice !== discountedPrice ? (<DeletedPrice> {totalPrice}</DeletedPrice>) : ''}
    </Fragment> );
};

export const PROGRAM_LENGTHS_V2 = [
  {
    name: '1 Week',
    value: '1',
    default: false,
    subLabel: (
      <span>
        <b>Reset:</b> feel lighter, less bloated, more energized
      </span>
    ),
  },
  {
    name: '4 Weeks',
    value: '4',
    default: false,
    hint: 'MOST POPULAR',
    subLabel: (
      <span>
        {' '}
        <b>Transform:</b> banish cravings and feel amazing
      </span>
    ),
  },
  {
    name: '8 Weeks',
    value: '8',
    default: false,
    subLabel: (
      <span>
        {' '}
        <b>Commit:</b> build lasting habits and your best body
      </span>
    ),
  },
];
function BundleForm(props) {
  const { programId, scheduleId, sizeId, mealTypeId, programSchedule } = props;
  const { displayApolloResponseErrors } = useErrorHandlers();
  const bundle = programBundleCartVar();

  const defaultNumWeeks = (PROGRAM_LENGTHS_V2.find((l) => l.default) || PROGRAM_LENGTHS_V2[0])
    .value;
  const isOneTimeProgram = programSchedule === PROGRAM_SCHEDULES_KEYS.ONE_TIME_PURCHASE;
  const isWeeklyProgram = programSchedule === PROGRAM_SCHEDULES_KEYS.WEEKLY_SUBSCRIPTION;

  const [numWeeks, setNumWeeks] = useState(bundle.numWeeks || defaultNumWeeks);
  const [bundleId, setBundleId] = useState(null);
  const [startDayId, setStartDayId] = useState('');
  const [dietryRestriction, setDietryRestriction] = useState(false);
  const [timingRestriction, setTimingRestriction] = useState(false);

  const {
    loading,
    refetch: refetchProgramBundle,
    data,
  } = useQuery(GET_PROGRAM_BUNDLE, {
    variables: {
      programId, scheduleId, sizeId, mealTypeId,
      numWeeks: isWeeklyProgram ? 1 : parseInt(numWeeks),
      otpSubscribed: isWeeklyProgram,
    },
    onCompleted: (response) => {
      const bundleId = response && response.getProgramBundle ? response.getProgramBundle.id : null;
      // eslint-disable-next-line no-unused-vars
      setBundleId((_) => bundleId);
    },
    notifyOnNetworkStatusChange: true,
  });

  const price =
    data && data.getProgramBundle && data.getProgramBundle.price !== undefined
      ? data.getProgramBundle.price
      : 0;
  const availableDeliveryDays =
    data && data.getProgramBundle && data.getProgramBundle.availableDeliveryDays !== undefined
      ? data.getProgramBundle.availableDeliveryDays
      : [];

  const discountedWeeklyPrices = data && data.getProgramBundle && data.getProgramBundle.discountedWeeklyPrices !== undefined
  ? data.getProgramBundle.discountedWeeklyPrices
  : [];

  useEffect(() => {
    const week2Price = discountedWeeklyPrices && discountedWeeklyPrices.find((weekPrice) => weekPrice.week === 2);
    if (isWeeklyProgram && week2Price && numWeeks !== '2') {
      setNumWeeks(week2Price && week2Price.week.toString());
      refetchProgramBundle();
    }
    if (isOneTimeProgram && parseInt(numWeeks) === 2) {
      setNumWeeks(defaultNumWeeks);
      refetchProgramBundle();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeeklyProgram, discountedWeeklyPrices]);

  const resetState = () => {
    // eslint-disable-next-line no-unused-vars
    setBundleId((_) => null);

    // const event_date = availableDeliveryDays &&  availableDeliveryDays.find((d) => d.dateWithoutOffset === '2024-05-06');
    const default_date = '';

    setStartDayId(default_date);

    refetchProgramBundle();
  };
  useEffect(resetState, [props, refetchProgramBundle, numWeeks]);

  const [createOneTimeProgram, { loading: isSaving }] = useMutation(CREATE_ONE_TIME_PROGRAM, {
    update: (
      cache,
      {
        data: {
          createOneTimeProgram: { oneTimeProgram, errors },
        },
      },
    ) => {
      updateUserQuery(
        cache,
        { user: { nextOneTimeProgram: { bundleReservations: null } } },
        errors,
      ); // clear the reservations array
      updateUserQuery(cache, { user: { nextOneTimeProgram: { ...oneTimeProgram } } }, errors);
    },
  });
  const disabled =
    [bundleId, numWeeks, startDayId].some(isEmpty) ||
    isSaving ||
    !dietryRestriction ||
    !timingRestriction ||
    !price;

  const onServerError = (error) => {
    const errors = [error].flatten();
    displayApolloResponseErrors(errors);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = { programId, sizeId, mealTypeId, scheduleId, numWeeks, bundleId, startDayId };
    session.setItem(SESSION_PROGRAM_BUNDLE_CART_KEY, JSON.stringify(data));
    programBundleCartVar(data);

    const input = {
      programBundleId: bundleId,
      numWeeks: isWeeklyProgram ? 1 : toInteger(numWeeks),
      startDayId: startDayId,
      otpSubscribed: programSchedule === PROGRAM_SCHEDULES_KEYS.WEEKLY_SUBSCRIPTION,
    };
    createOneTimeProgram({ variables: { input } })
      .then(
        ({
          data: {
            createOneTimeProgram: { errors = [] },
          },
        }) => {
          errors.length ? onServerError(errors) : props.onSuccess();
        },
      )
      .catch(({ errors = [] }) => {
        if (errors.length) {
          onServerError(errors);
        }
      });
  };

  const formatDateOption = useCallback(
    (availableDeliveryDays) =>
      availableDeliveryDays.map((d) => {
        const title = `${displayDate(d.dateWithoutOffset, 'deliveryDate')}${
          d.locked || d.decOff ? ' (SOLD OUT)' : ''
        }`;

        return {
          title,
          value: d.id,
          disabled: d.locked || d.decOff,
        };
      }),
    [],
  );

  //TODO: Move logic to Account and fix routing
  // const isPresubOrCouriorRegion = props.currentUser.primaryAddress && props.currentUser.primaryAddress.deliveryOption && props.currentUser.primaryAddress.deliveryOption.deliveryMethod === 'COURIER';

  return (
    <ContentForm onSubmit={onSubmit} noValidate instantValidate={false}>
      {isOneTimeProgram &&
        <CustomFormGroup role="radiogroup" aria-labelledby="program_length">
          <InputGroupLabel id="program_length">Program Length</InputGroupLabel>
          <InputGroupHint key={'program_length-subheading'}>
            Save 10% on 4- and 8-weeks programs
          </InputGroupHint>
          <Grid gap="1.2em">
            {PROGRAM_LENGTHS_V2.map((option) => (
              <Radio
                key={`numWeeks-${option.value}`}
                name="numWeeks"
                label={<ProgramLengthLabel weeks={option.value} price={price} discountedWeeklyPrices={discountedWeeklyPrices} />}
                value={option.value}
                subLabel={option.subLabel}
                hint={option.hint}
                checked={!!numWeeks && numWeeks === option.value}
                onChange={(e) => setNumWeeks(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>
      }
      <CustomFormGroup role="checkbox" aria-labelledby="dietaryRestrictions">
        <InputGroupLabel id='dietaryRestrictions'>Order and Dietary Restrictions</InputGroupLabel>
        <CustomCheckbox
          label="I understand that you can’t accommodate food allergies or dietary restrictions, with the exception of allowing up to two meal swaps per week. I also understand that your menu is free of gluten and dairy."
          name="dietryRestriction"
          value="dietryRestriction"
          onChange={() => setDietryRestriction((value) => !value)}
          checked={dietryRestriction}
        />
      </CustomFormGroup>

      <CustomFormGroup>
        <CustomCheckbox
          label={
            <span>
              I understand that if I need to modify or postpone my order, I must email
              <InnerLink href="mailto:help@gomethodology.com"> help@gomethodology.com</InnerLink> by
              9 PM PT on the Sunday one week before my scheduled delivery, and any requests after
              that cannot be accommodated.
            </span>
          }
          name="timingRestriction"
          value="timingRestriction"
          onChange={() => setTimingRestriction((value) => !value)}
          checked={timingRestriction}
        />
      </CustomFormGroup>

      <CustomFormGroup>
        <InputGroupLabel>Start Date</InputGroupLabel>
        {!loading ? (
          <CustomSelect
            name="startDayId"
            options={formatDateOption(availableDeliveryDays)}
            selected={startDayId}
            onChange={(e) => setStartDayId(e.target.value)}
            prompt="Select a start date"
          />
        ) : (
          <CustomSelect
            name="startDayId"
            options={[]}
            selected={''}
            prompt="Loading..."
            readOnly={true}
          />
        )}
      </CustomFormGroup>

      <CustomContentButton type="submit" disabled={loading || disabled}>
        <div aria-live="polite">Total {loading ? '...' :priceLabel(price, numWeeks, false, isWeeklyProgram)} - CHECKOUT </div>
      </CustomContentButton>
    </ContentForm>
  );
}

BundleForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  sizeId: PropTypes.string.isRequired,
  mealTypeId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  scheduleId: PropTypes.string.isRequired,
  programSchedule: PropTypes.string,
};

//--------------

export function StepForm(props) {
  const { onSuccess } = props;
  const bundle = programBundleCartVar();
  const [programId, setProgramId] = useState(bundle.programId);
  const [sizeId, setSizeId] = useState(bundle.sizeId);
  const [scheduleId, setScheduleId] = useState(bundle.scheduleId);
  const [mealTypeId, setMealTypeId] = useState(bundle.mealTypeId);
  const [schedulesList, setSchedulesList] = useState([]);
  const [mealTypesList, setMealTypes] = useState([]);
  const [filteredSchedulesList, setFilteredSchedulesList] = useState([]);

  const programFoods = PROGRAM_FOOD_OPTIONS;

  useEffect(() => {
    const data = JSON.parse(session.getItem(SESSION_PROGRAM_BUNDLE_CART_KEY) || '{}');
    const { programId, sizeId, scheduleId, numWeeks, bundleId, startDayId, mealTypeId } = data;
    programBundleCartVar({
      programId,
      sizeId,
      scheduleId,
      numWeeks,
      bundleId,
      startDayId,
      mealTypeId,
    });
    setProgramId(programId);
    setSizeId(sizeId);
    setScheduleId(scheduleId);
    setMealTypeId(mealTypeId);
  }, []);

  useEffect(() => {
    if (schedulesList.length && mealTypeId) {
      // eslint-disable-next-line eqeqeq
      const selectedFoodPreference = mealTypesList.find((f) => f.id == mealTypeId);
      let filteredList = schedulesList.filter((s) => {
        if (selectedFoodPreference && selectedFoodPreference.tags) {
          return selectedFoodPreference.tags.includes(s.name);
        }
        return true;
      });

      if (!filteredList.length) {
        filteredList = schedulesList;
      }

      setFilteredSchedulesList(sortBy(filteredList, 'name'));
      const defaultSchedule = filteredList.find((s) => s.default) || filteredList[0];

      setScheduleId(defaultSchedule.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealTypeId, programFoods, schedulesList]);

  // TODO: Implement this as a real attribute on Programs::PrepaidBundle

  const {
    loading,
    data: {
      listPrepaidPrograms: programs,
      // eslint-disable-next-line no-unused-vars
      listProgramSchedules: programSchedules,
      listProgramSizes: programSizes,
      listProgramMealTypes: programMealTypes,
    } = {},
  } = useQuery(LIST_PROGRAM_OPTIONS, {
    onCompleted: ({
      listPrepaidPrograms: programs,
      listProgramSchedules: schedules,
      listProgramSizes: sizes,
      listProgramMealTypes: mealTypes,
    }) => {
      const isProgramIdPresent = programOptions.some((program) => program.id === programId);
      if (!isProgramIdPresent && programs) {
        //comment out below code to Disable to AUTOSELECT program.
        const defaultProgram = programOptions.find((p) => p.default) || programOptions[0];
        setProgramId(defaultProgram.id);
      }
      if (!sizeId && sizes) {
        const defaultSize = sizes.find((s) => s.default) || sizes[0];
        setSizeId(defaultSize.id);
      }
      if (schedules) {
        setSchedulesList(schedules);
      }
      if (mealTypes) {
        const defaultFood = mealTypes.find((s) => s.default) || mealTypes[0];
        const newMealTypes = mealTypes.slice().map((m) => {
          let tags = [];
          if (m.name.includes('Without')) {
            // TODO: SET AN Identifier;
            tags = ['5 Days'];
          } else {
            tags = ['4 Days', '3 Days', '2 Days', '1 Day'];
          }
          return { ...m, tags };
        });
        setMealTypes(newMealTypes);
        if (!mealTypeId) {
          setMealTypeId(defaultFood.id);
        }
      }
    },
  });

  const programOptions = sortBy(programs, 'position').filter((item) => !item.name.toLowerCase().includes('haute mom'));
  const sizeOptions = sortBy(programSizes, 'position');

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <Form>
        <CustomFormGroup role="radiogroup" aria-labelledby="foodType">
          <CustomInputGroupLabel id="foodType">Which Meals?</CustomInputGroupLabel>
          <Grid gap="1.2em">
            {programMealTypes.map((option) => (
              <CustomRadio
                key={`food-${option.id}`}
                name="food"
                label={<SanitizedHTML rawHTML={option.description} />}
                value={option.id}
                checked={!!mealTypeId && mealTypeId === option.id}
                onChange={(e) => setMealTypeId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup role="radiogroup" aria-labelledby="portion_size">
          <CustomInputGroupLabel id="portion_size">Meal Portion Size</CustomInputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>
            {sizeOptions.map((option) => (
              <Radio
                className={option.name.toLowerCase().includes('couple') ? 'span_2' : ''}
                key={`size-${option.id}`}
                name="size"
                value={option.id}
                label={option.name}
                subLabel={stringWithoutTags(option.description)}
                checked={!!sizeId && sizeId === option.id}
                onChange={(e) => setSizeId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup role="radiogroup" aria-label="daysPerWeek">
          <CustomInputGroupLabel id="daysPerWeek">Days of Food</CustomInputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>
            {filteredSchedulesList.map((option) => (
              <Radio
                key={`schedule=${option.id}`}
                name="schedule"
                label={displayScheduleLabel(option)}
                value={option.id}
                checked={!!scheduleId && scheduleId === option.id}
                onChange={(e) => setScheduleId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup role="radiogroup" aria-labelledby="dietType">
          <CustomInputGroupLabel id="dietType">Diet</CustomInputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>
            {programOptions.map((option) => (
              <Radio
                key={`program-input-${option.id}`}
                name="program"
                label={option.name}
                value={option.id}
                checked={!!programId && programId === option.id}
                onChange={(e) => setProgramId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>
      </Form>

      {programId && sizeId && mealTypeId && scheduleId && (
        <BundleForm
          currentUser={props.currentUser}
          onSuccess={onSuccess}
          programId={programId}
          sizeId={sizeId}
          mealTypeId={mealTypeId}
          scheduleId={scheduleId}
          programSchedule={props.programSchedule}
        />
      )}
    </Fragment>
  );
}

StepForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  programSchedule: PropTypes.string,
};

function SignatureProgramPage(props) {
  const onSuccess = () => onrampNext(props.currentUser, props.cache);

  return <StepForm onSuccess={onSuccess} currentUser={props.currentUser} cache={props.cache} programSchedule={props.selectedProgramSchedule}  />;
}

SignatureProgramPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  selectedProgramSchedule: PropTypes.string,
};

const CustomContentButton = styled(ContentButton)`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  width: 100%;
  padding: 12px 35px;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background: #c1d3c1;
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 1.5em;
`;

const CustomSelect = styled(Select)`
  margin-bottom: 1em;

  p.select-selected {
    margin: 0.5em 0 2.5em;
  }
`;

const CustomRadio = styled(Radio)`
  div#heading {
      max-width: 60%;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  font-size: ${(p) => p.theme.typography.sizes.small};
  .checkbox {
    margin-right: 1em;
    font-size: ${(p) => p.theme.typography.sizes.big};
    align-item: top;
  }
`;

const CustomInputGroupLabel = styled(InputGroupLabel)`
  padding-bottom: 0em;
`;

const InputGroupHint = styled(Hint)`
  font-size: 0.8em;
  margin-bottom: 1em;
  margin-left: 0em;
  color: ${(p) => p.theme.colors.primary};
`;

const InnerLink = styled.a`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const DeletedPrice = styled.del`
  color: rgba(54, 68, 58, 0.50);
  font-size: ${(p) => p.theme.typography.sizes.smaller};
  margin-left: 0.2rem;
`;

export default SignatureProgramPage;
